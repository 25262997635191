import React from 'react';
import './contact-us.css';
import { FaEnvelope } from 'react-icons/fa';

const ContactUs = () => {
  document.title = "Contactez-nous | AceBlog";
  // Check if the meta title already exists
  let metaTitleTag = document.querySelector('meta[name="title"]');
  if (!metaTitleTag) {
    // Create and append the meta title if it does not exist
    metaTitleTag = document.createElement("meta");
    metaTitleTag.name = "title";
    document.head.appendChild(metaTitleTag);
  }
  metaTitleTag.content = "Contactez-nous | AceBlog";
  return (
    <div className="contact-us-container">
      <div className="contact-us-content">
        <h2 className="contact-us-heading">Comment pouvons-nous vous aider ?</h2>
        <p className="contact-us-tagline">
          Si vous avez des suggestions ou des questions, veuillez nous contacter en utilisant les coordonnées ci-dessous. Nous sommes impatients de vous entendre.
        </p>
        <div className="contact-us-email-section">
          <FaEnvelope className="email-icon" />
          <a className="email-link" href="mailto:info@aceblog.fr">info@aceblog.fr</a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
