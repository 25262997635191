import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import htmlParser from "html-react-parser";
import Sidebar from "./sidebar";
import "../home/mainContent/homes/style.css";
import "./newsDetail.css";
import "../home/sideContent/side/side.css";
import Error404 from "./NotFound";

const NewsDetail = () => {
  const { slug } = useParams();
  const [newsDetail, setNewsDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNewsDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://aceblog.fr/admin/api/news/details/${slug}`);
        const news = response.data?.news;
        if (news) {
          setNewsDetail(news);
        } else {
          setNewsDetail(null);
        }
      } catch (err) {
        console.error("Erreur lors de la récupération des détails de l'actualité:", err);
        setError("Échec de la récupération des détails de l'actualité. Veuillez réessayer plus tard.");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsDetails();
  }, [slug]);

  useEffect(() => {
    if (newsDetail) {
      document.title = newsDetail.title || "AceBlog";

      let metaTitleTag = document.querySelector('meta[name="title"]');
      if (!metaTitleTag) {
        metaTitleTag = document.createElement("meta");
        metaTitleTag.name = "title";
        document.head.appendChild(metaTitleTag);
      }
      metaTitleTag.content = newsDetail.meta_title || newsDetail.title;

      let metaDescriptionTag = document.querySelector('meta[name="description"]');
      if (!metaDescriptionTag) {
        metaDescriptionTag = document.createElement("meta");
        metaDescriptionTag.name = "description";
        document.head.appendChild(metaDescriptionTag);
      }
      const metaDescriptionContent = (newsDetail.meta_description || newsDetail.description || "");
      metaDescriptionTag.content = metaDescriptionContent;

      const jsonLd = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        headline: newsDetail.title,
        description: metaDescriptionContent,
        datePublished: newsDetail.date,
        author: {
          "@type": "Person",
          name: newsDetail.writerName || "Unknown",
        },
        image: newsDetail.image || "https://via.placeholder.com/1200x600",
        url: window.location.href,
      };

      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.innerHTML = JSON.stringify(jsonLd);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [newsDetail]);
  if (error) return <Error404/>;
  // Render the content of the news article
  const renderContent = () => {
    if (loading) return <div className="loading-spinner">Chargement...</div>;
    if (!newsDetail) return <div className="no-news">Aucun détail d'actualité trouvé.</div>;

    return (
      <div>
        <div className="news-header">
          <h1 className="news-title">{newsDetail.title}</h1>
          <div className="news-meta">
            <span className="news-date">
              {new Date(newsDetail.date).toLocaleDateString()}
            </span>
            <Link to={`/autor/${newsDetail.writerName}`}>
            <span className="news-author">
              Par {newsDetail.writerName || "Unknown"}
            </span></Link>
          </div>
          <img
            src={newsDetail.image || "https://via.placeholder.com/1200x600"}
            alt={newsDetail.title || "No title available"}
            className="news-image"
          />
        </div>

        <div className="news-body">
          <div className="news-description">
            {htmlParser(newsDetail.description || "No description available.")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="news-detail-container">
      <div className="content-with-sidebar">
        <div className="news-detail-content">{renderContent()}</div>
        <div className="sidebar-container">
          <Sidebar />
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
