import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // For navigation
import './sidebar.css'; // Your styles here
import htmlParser from "html-react-parser"; // Import the HTML parser

const Sidebar = () => {
  const [recentNews, setRecentNews] = useState([]); // State for recent news
  const [categories, setCategories] = useState([]); // State for categories
  const [loading, setLoading] = useState(true); // Loading state for fetching data
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    // Fetch recent news and categories from the API
    const fetchData = async () => {
      try {
        const newsResponse = await axios.get('https://aceblog.fr/admin/api/recent/news');
        const categoriesResponse = await axios.get('https://aceblog.fr/admin/api/categories');
        
        // Set the data to state
        setRecentNews(newsResponse.data.news);
        setCategories(categoriesResponse.data.categories);
        setLoading(false);
      } catch (err) {
        console.error("Erreur lors de la récupération des données :", err);
        setError("Échec du chargement des données.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const truncateText = (text, limit) => {
    if (text && text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  return (
    <div className="sidebar">
      {/* Loading and error handling */}
      {loading ? (
        <div className="loading-message">Chargement...</div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <>
          {/* Recent Posts Section */}
          <div className="recent-posts">
            <h3>Messages récents</h3>
            <ul>
              {recentNews.length > 0 ? (
                recentNews.map((post) => (
                  <li key={post.slug} className="recent-post-item">
                    <Link to={`/${post.slug}`} className="recent-post-link">
                      <div className="recent-post">
                        {post.image && (
                          <img
                            src={post.image}
                            alt={post.title}
                            className="recent-post-image"
                          />
                        )}
                        <span className="recent-post-title">{htmlParser(truncateText(post.title, 50))}</span>
                      </div>
                    </Link>
                  </li>
                ))
              ) : (
                <li>Aucun message récent disponible.</li>
              )}
            </ul>
          </div>

          {/* Categories Section */}
          <div className="categories">
            <h3>Catégories</h3>
            <ul>
              {categories.length > 0 ? (
                categories.map((category) => (
                  <li key={category.id}>
                    <Link to={`/categorie/${category.name ? category.name : ''}`}>
                      {category.name || 'Unnamed Category'}
                    </Link>
                  </li>
                ))
              ) : (
                <li>Aucune catégorie disponible.</li>
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;