import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import htmlParser from "html-react-parser";
import "../home/mainContent/homes/style.css";
import "./singlepage.css";
import "../home/sideContent/side/side.css";

const SinglePage = () => {
  const { category } = useParams(); // Destructure category from URL
  const [newsList, setNewsList] = useState([]); // State for the list of news
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const itemsPerPage = 20; // Number of items per page

  useEffect(() => {
    setLoading(true); // Set loading state to true when fetching
    document.title = category + " | AceBlog";
    // Check if the meta title already exists
    let metaTitleTag = document.querySelector('meta[name="title"]');
    if (!metaTitleTag) {
      // Create and append the meta title if it does not exist
      metaTitleTag = document.createElement("meta");
      metaTitleTag.name = "title";
      document.head.appendChild(metaTitleTag);
    }
    metaTitleTag.content = category + " | AceBlog";

    if (category) {
      axios
        .get(`https://aceblog.fr/admin/api/category/news/${category}`)
        .then((response) => {
          if (response.data && Array.isArray(response.data.news) && response.data.news.length > 0) {
            const sortedNews = response.data.news.sort((a, b) => new Date(b.date) - new Date(a.date));
            setNewsList(sortedNews); // Set the news list for the category
          } else {
            setNewsList([]); // Set as empty if no news is found
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error("Erreur lors de la récupération des actualités de la catégorie :", err);
          setError("Échec de la récupération des actualités de la catégorie.");
          setLoading(false);
        });
    }
  }, [category]); // Re-run effect when category changes

  const truncateText = (text, limit) => {
    if (text && text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  // Pagination logic: Slice news for the current page
  const totalPages = Math.ceil(newsList.length / itemsPerPage);
  const currentNews = newsList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
  };

  return (
    <div className="single-page-container">
      {loading ? (
        <p>Chargement...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div>
          <h1>Actualités dans la catégorie: {category}</h1>
          <div className="news-cards-container">
            {Array.isArray(currentNews) && currentNews.length > 0 ? (
              currentNews.map((news) => (
                <div key={news._id} className="news-card">
                  <a href={`/${news.slug}`} className="read-more-link">
                    <img
                      src={news.image || "https://via.placeholder.com/300"}
                      alt={news.title || "No title available"}
                      className="news-card-image"
                    />
                  </a>
                  <div className="news-card-body">
                    <a href={`/${news.slug}`} className="read-more-link">
                      <h4>{htmlParser(truncateText(news.title, 50))}</h4>
                    </a>
                    <a href={`/${news.slug}`} className="read-more-link">
                      <p>{htmlParser(truncateText(news.description || "No description available.", 100))}</p>
                    </a>
                    <a href={`/${news.slug}`} className="read-more-link">
                      En savoir plus
                    </a>
                  </div>
                </div>
              ))
            ) : (
              <p>Aucune actualité trouvée dans cette catégorie.</p>
            )}
          </div>

          {/* Pagination Controls */}
          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Précédent
            </button>
            <span>Page {currentPage} sur {totalPages}</span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Suivant
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SinglePage;