import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./sidebar";
import "../home/mainContent/homes/style.css";
import "./errorPage.css"; // Add a custom CSS for error styling
import "../home/sideContent/side/side.css";

const Error404 = () => {
  useEffect(() => {
    document.title = "404 - Page introuvable";
    let metaTitleTag = document.querySelector('meta[name="title"]');
    if (!metaTitleTag) {
      metaTitleTag = document.createElement("meta");
      metaTitleTag.name = "title";
      document.head.appendChild(metaTitleTag);
    }
    metaTitleTag.content = "404 - Page introuvable";

    let metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (!metaDescriptionTag) {
      metaDescriptionTag = document.createElement("meta");
      metaDescriptionTag.name = "description";
      document.head.appendChild(metaDescriptionTag);
    }
    metaDescriptionTag.content = "The page you're looking for doesn't exist.";

    const jsonLd = {
      "@context": "https://schema.org",
      "@type": "WebPage",
      headline: "404 - Page Not Found",
      description: "The page you're looking for doesn't exist.",
      url: window.location.href,
    };

    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(jsonLd);
    document.body.appendChild(script);

    // Redirect to the home page after 1 second
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="error-page-container">
      <div className="content-with-sidebar">
        <div className="error-page-content">
          <h1 className="error-title">404</h1>
          <p className="error-message">Oups ! La page que vous recherchez est introuvable.</p>
          <p className="error-description">
            Il a peut-être été supprimé ou vous avez peut-être mal saisi l'adresse.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Error404;
