import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import htmlParser from "html-react-parser"; // Import the html-react-parser library
import "./SearchResults.css"; // Import CSS for styling

const SearchResults = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query") || ""; // Fallback if query is empty
  document.title = "Search | AceBlog";
  // Check if the meta title already exists
  let metaTitleTag = document.querySelector('meta[name="title"]');
  if (!metaTitleTag) {
    // Create and append the meta title if it does not exist
    metaTitleTag = document.createElement("meta");
    metaTitleTag.name = "title";
    document.head.appendChild(metaTitleTag);
  }
  metaTitleTag.content = "Search | AceBlog";
  useEffect(() => {
    const fetchResults = async () => {
      if (!query) {
        setError("Aucune requête de recherche fournie.");
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const response = await axios.get(
          `https://aceblog.fr/admin/api/search/news?value=${query}`
        );

        setResults(response.data.news || []);
        setError(null);
      } catch (error) {
        console.error("Erreur lors de la récupération des résultats de recherche :", error);
        setError(
          error.response?.data?.message ||
            "Erreur interne du serveur. Veuillez réessayer plus tard."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [query]);

  return (
    <div className="search-results">
      <h1>Résultats de la recherche pour "{query}"</h1>
      {loading && <p>Chargement...</p>}
      {error && <p>{error}</p>}
      {!loading && !error && results.length > 0 ? (
        <div className="results-grid">
          {results.map((result, index) => (
            <div key={index} className="result-card">
                <Link to={`/${result.slug}`}>
              <img
                src={result.image || "default-image.jpg"} // Replace with default image if necessary
                alt={result.title || "No Title"}
                className="result-image"
              />
              </Link>
              <h2 className="result-title">
                <Link to={`/${result.slug}`}>
                {htmlParser(result.title.slice(0, 40))}...
                </Link>
              </h2>
              <Link to={`/${result.slug}`}>
              <p className="result-excerpt">
                {htmlParser(result.description.slice(0, 80))}...
              </p>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        !loading && !error && <p>Aucun résultat trouvé</p>
      )}
    </div>
  );
};

export default SearchResults;