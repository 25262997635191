import React, { useState, useEffect } from "react";
import Heading from "../../common/heading/Heading";
import "./style.css";

// Function to limit title to 40 words
const limitTitle = (title, wordLimit = 10) => {
  const words = title.split(" "); // Split the title into words
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "..."; // Join first 40 words and add ellipsis
  }
  return title; // Return title as is if it's under the word limit
};

const Discover = () => {
  const [discoverNews, setDiscoverNews] = useState([]); // State to store fetched news
  const [loading, setLoading] = useState(true); // State to manage loading status

  // Fetch news from API
  useEffect(() => {
    const fetchDiscoverNews = async () => {
      try {
        const response = await fetch("https://aceblog.fr/admin/api/category/news/Voyage");
        const data = await response.json(); // Parse JSON response
        // console.log("Fetched discover news:", data); // Log the response for debugging

        // Check if 'news' is an array and set the data to state
        if (Array.isArray(data.news)) {
          const sortedNews = data.news.sort((a, b) => new Date(b.date) - new Date(a.date));
          setDiscoverNews(sortedNews);
        } else {
          console.error("Le champ 'news' n'est pas un tableau :", data.news);
        }
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Erreur lors de la récupération des actualités du voyage :", error);
        setLoading(false); // Set loading to false on error
      }
    };

    fetchDiscoverNews(); // Fetch the news data
  }, []); // Empty dependency array means this effect runs once after the component mounts

  return (
    <section className="discover">
      <div className="container">
        <Heading title="Voyage" />
        <div className="content">
          {loading ? (
            <p>Chargement...</p> // Display loading message while fetching data
          ) : (
            discoverNews.length > 0 ? (
              discoverNews.map((val, index) => {
                return (
                  <div className="box" key={index}>
                    <div className="img">
                      <a href={`/${val.slug}`}><img src={val.image} alt={val.title} /></a>
                    </div>
                    {/* Use dangerouslySetInnerHTML to render HTML content */}
                    <a href={`/${val.slug}`}>
                      <h1
                        className="title"
                        dangerouslySetInnerHTML={{ __html: limitTitle(val.title) }}
                      />
                    </a>
                  </div>
                );
              })
            ) : (
              <p>Aucune nouvelle récente disponible.</p> // Display message if no news is available
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Discover;
