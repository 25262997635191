import React, { useState, useEffect } from "react";
import Head from "./Head";
import "./header.css";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State to track search input
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://aceblog.fr/admin/api/categories");
        const data = await response.json();
        setCategories(data.categories);
      } catch (error) {
        console.error("Erreur lors de la récupération des catégories :", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();

    if (searchQuery.trim()) {
      navigate(`/recherche?query=${encodeURIComponent(searchQuery)}`); // Navigate to search page with query
      setSearchQuery(""); // Clear the search input after navigating
    } else {
      alert("Veuillez saisir une requête de recherche."); // Notify user about empty search
    }
  };

  return (
    <>
      <Head />
      <header>
        <div className="container paddingSmall">
          <nav>
            <ul className={navbar ? "navbar" : "flex"} onClick={() => setNavbar(false)}>
              <li>
                <Link to="/">Maison</Link>
              </li>
              <li>
                <Link to="/nouvelles">Nouvelles</Link>
              </li>
              {categories.length > 0 ? (
                categories.slice(0,5).map((category, index) => (
                  <li key={index}>
                    <Link to={`/categorie/${category.name}`}>{category.name}</Link>
                  </li>
                ))
              ) : (
                <li>Chargement des catégories...</li>
              )}
            </ul>
            <button aria-label="Menu" className="barIcon" onClick={() => setNavbar(!navbar)}>
              {navbar ? <i className="fa fa-times"></i> : <i className="fa fa-bars"></i>}
            </button>
            <form className="search-container" onSubmit={handleSearch}>
              <input
                type="text"
                placeholder="Recherche..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update search query state
              />
              <button aria-label="Search" className="search-icon" type="submit">
                <i className="fa fa-search"></i>
              </button>
            </form>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;