import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Heading from "../../../common/heading/Heading";
import htmlParser from "html-react-parser";
import "./ppost.css";
import DateComponent from "../../../date";

const Ppost = () => {
  const [posts, setPosts] = useState([]); // State to store fetched posts
  const [loading, setLoading] = useState(true); // State for loading status

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 768, // For screens 768px and smaller
        settings: {
          slidesToShow: 1, // Show 1 slide
          slidesToScroll: 1, // Scroll 1 slide at a time
        },
      },
    ],
  };
  

  // Fetch posts from API
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch("https://aceblog.fr/admin/api/category/news/Finance");
        const rawData = await response.text();

        try {
          const data = JSON.parse(rawData); // Parse JSON response

          if (Array.isArray(data.news)) {
            const sortedNews = data.news.sort((a, b) => new Date(b.date) - new Date(a.date));
            setPosts(sortedNews.slice(0, 6));
          } else {
            console.error("Le champ 'news' n'est pas un tableau :", data.news);
          }
        } catch (e) {
          console.error("Erreur lors de l'analyse de JSON :", e);
        }

        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des messages :", error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  return (
    <section className="popularPost">
      <Heading title="Finance" />
      <div className="content">
        {loading ? (
          <p>Chargement des nouvelles de la Finance...</p>
        ) : (
          <Slider {...settings}>
            {posts.map((val, index) => (
              <div key={index} className="items">
                <div className="box shadow">
                  <div className="images">
                    <div className="img">
                      <Link to={`/${val.slug}`}>
                        <img src={val.image} alt={val.title} />
                      </Link>
                    </div>
                    <div className="category category1">
                      <Link to={`/categorie/${val.category}`}>
                      <span>{val.category}</span></Link>
                    </div>
                  </div>
                  <div className="text">
                    <Link to={`/${val.slug}`}>
                      <h1 className="title">{htmlParser(val.title.slice(0, 80))}</h1>
                    </Link>
                    <div className="date">
                      <i className="fas fa-calendar-days"></i>
                      <DateComponent date={val.date} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </section>
  );
};

export default Ppost;