import React, { useState, useEffect } from "react";
import "./side.css";
import { Link } from "react-router-dom";
import Heading from "../../../common/heading/Heading";
import SocialMedia from "../social/SocialMedia";

const Side = () => {
  const [categories, setCategories] = useState([]); // State to store categories
  const [loading, setLoading] = useState(true); // State to handle loading

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Fetch categories from API
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch("https://aceblog.fr/admin/api/categories");
        if (!response.ok) {
          throw new Error(`Erreur HTTP ! statut: ${response.status}`);
        }
  
        const data = await response.json();
        // console.log("Fetched data:", data); // Log the fetched data
  
        // Ensure categories are available and in the correct format
        if (data && Array.isArray(data.categories)) {
          setCategories(data.categories); // Set fetched categories to state
          // console.log("Categories after set:", data.categories); // Log categories after setting state
        } else {
          console.error("Format de données inattendu. Les « catégories » ne peuvent pas être un tableau.");
        }
  
        setLoading(false); // Update loading state
      } catch (error) {
        console.error("Erreur lors de la récupération des catégories :", error);
        setLoading(false); // Handle errors
      }
    };
  
    fetchCategories(); // Call the function to fetch categories
  }, []); // Empty dependency array ensures this runs only once on mount
  

  return (
    <>
      <Heading title="Restez connecté" />
      <SocialMedia />

      <Heading title="S'abonner" />

      <section className="subscribe">
        <h1 className="title">Abonnez-vous à nos nouvelles histoires</h1>
        <form action="">
          <input type="email" placeholder="Adresse Email...." />
          <button>
            <i className="fa fa-paper-plane"></i> SOUMETTRE
          </button>
        </form>
      </section>

      {/* <section className="banner">
        <img src="./images/sidebar-banner-new.jpg" alt="" />
      </section> */}

    <section className="categories">
      <Heading title="Catégories" />
      
      {loading ? (
        <p>Chargement des catégories...</p> // Show loading message while fetching categories
      ) : categories.length > 0 ? (
        categories.map((category, index) => {
          // Log category object to check its structure
          // console.log("Category:", category); 

          // Check if category.name exists to avoid rendering issues
          return (
            <div key={index} className="categories-side">
              <Link to={`/categorie/${category.name}`}>
                <span>{category.name ? category.name : 'Nom de la catégorie indisponible'}</span>
              </Link>
            </div>
          );
        })
      ) : (
        <p>Aucune catégorie disponible.</p> // Show this if there are no categories
      )}
    </section>

    </>
  );
};

export default Side;
