import React, { useState, useEffect } from "react";
import "./Popular.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Heading from "../../../common/heading/Heading";
import htmlParser from "html-react-parser"; // Import the html-react-parser library
import DateComponent from "../../../date";
import WriterComponent from "../../../writer";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const Popular = () => {
  const [popular, setPopular] = useState([]); // State to store fetched popular news
  const [loading, setLoading] = useState(true); // State to handle loading state

  // Slider settings
  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 1, // 1 column per row (now single column layout)
    slidesPerRow: 1, // 1 slide per row
    speed: 500, // Duration of the transition (in ms)
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Change slide every 2 seconds (2000 ms)
    pauseOnHover: true, // Pause autoplay when mouse is over the slider
    pauseOnFocus: true, // Pause autoplay when slider is focused
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1, // 1 column per row on smaller screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Fetch popular news from new API
  useEffect(() => {
    const fetchPopularNews = async () => {
      try {
        const response = await fetch("https://aceblog.fr/admin/api/category/news/Entreprise");
        const data = await response.json();
        
        // Log the entire response to see the structure
        // console.log("Fetched data:", data);
        
        // Check if 'news' exists and is an array
        if (Array.isArray(data.news)) {
          // Sort the news array by date (assuming the date is in ISO string format)
          const sortedNews = data.news.sort((a, b) => new Date(b.date) - new Date(a.date));
          
          // Take the first 6 items from the sorted array
          setPopular(sortedNews.slice(0, 6));
        } else {
          console.error("Le champ 'news' n'est pas un tableau ou n'existe pas :", data.news);
        }
        
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des actualités populaires :", error);
        setLoading(false);
      }
    };
  
    fetchPopularNews();
  }, []); // Empty dependency array means this runs once when the component mounts
  

  return (
    <section className="popular">
      <Heading title="Entreprise" />
      <div className="content">
        <Slider {...settings}>
          <div className="grid-layout">
            <div className="column-1">
              {popular.slice(0, 6).map((val, index) => {
                return (
                    <div className="items">
                      <div className="box shadow">
                        <div className="images row">
                          <div className="img">
                          <a aria-label={val.title} href={`/${val.slug}`}><img src={val.image} alt="" /></a>
                          </div>
                          <div className="category category1">
                            <Link to={`/categorie/${val.category}`}>
                            <span>{val.category}</span></Link>
                          </div>
                        </div>
                        <div className="text row">
                          <a href={`/${val.slug}`}><h1 className="title">{htmlParser(val.title.slice(0, 40))}...</h1></a>
                          <div className="date">
                            <i className="fas fa-calendar-days"></i>
                            <DateComponent date={val.date} />
                          </div>
                          <div className="comment">
                            <Link to={`/autor/${val.writerName}`}>
                            <i className="fas fa-user"></i>
                            <WriterComponent writerName={val.writerName} /></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  
                );
              })}
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Popular;
