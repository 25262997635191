import React, { useState, useEffect } from "react";
import "./hero.css";
import Card from "./Card";

const Hero = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://aceblog.fr/admin/api/recent/news");
        if (!response.ok) {
          throw new Error("Échec de la récupération des données");
        }
        const data = await response.json();
        setItems(data.news.slice(0, 4)); // Limit to 4 recent news items
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  return (
    <section className="hero">
      <div className="container">
        {loading ? (
          <div className="skeleton-container">
            {/* Render 4 skeleton cards */}
            {[...Array(4)].map((_, index) => (
              <div key={index} className="skeleton-card"></div>
            ))}
          </div>
        ) : (
          items.map((item) => <Card key={item.title} item={item} />)
        )}
      </div>
    </section>
  );
};

export default Hero;
