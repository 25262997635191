import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./music.css";
import Slider from "react-slick";
import Heading from "../../../common/heading/Heading";
import htmlParser from "html-react-parser"; // Import the html-react-parser library
import DateComponent from '../../../date';

const Music = () => {
  const [news, setNews] = useState([]); // State to store news data
  const [loading, setLoading] = useState(true); // State to handle loading state
  const [currentPage, setCurrentPage] = useState(1); // State for pagination
  const newsPerPage = 6; // Number of news items to show per page
  const [totalPages, setTotalPages] = useState(1);

  // Slider settings
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  // Fetch music news from the API
  useEffect(() => {
    const fetchMusicNews = async () => {
      try {
        const response = await fetch(`https://aceblog.fr/admin/api/all/news_page?page=${currentPage}&limit=${newsPerPage}`);
        const data = await response.json();
        
        if (data && data.news && typeof data.news === "object") {
          setNews(data.news);
          setTotalPages(data.pagination.totalPages);
        } else {
          console.error("Le champ 'actualités' n'est pas disponible ou n'est pas un objet.");
        }

        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des actualités :", error);
        setLoading(false);
      }
    };

    fetchMusicNews();
  }, [currentPage]); // Re-fetch news when currentPage changes

  // Function to extract plain text from ReactHtmlParser content
  const extractText = (parsedContent) => {
    const getText = (content) => {
      if (typeof content === 'string') return content;
      if (Array.isArray(content)) return content.map(getText).join(' ');
      if (content && typeof content === 'object' && content.props) {
        return getText(content.props.children);
      }
      return '';
    };

    return getText(parsedContent);
  };

  // Function to get an excerpt (first 40 words) of the description
  const getExcerpt = (description) => {
    const parsedDescription = htmlParser(description);
    const textContent = extractText(parsedDescription);
    const words = textContent.split(" ").filter(word => word.trim());
    const excerpt = words.slice(0, 40).join(" ");
    return excerpt + (words.length > 40 ? "..." : "");
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <section className="music">
      <Heading title="Nos Actualités" />
      <div className="content">
        {loading ? (
          <p>Loading Nos Actualités News...</p>
        ) : (
          <Slider {...settings}>
            {news.length > 0 ? (
              news.map((val, index) => {
                return (
                  <div key={index} className="items">
                    <div className="box shadow flexSB">
                      <div className="images">
                        <div className="img">
                          <Link to={`/${val.slug}`}>
                            <img src={val.image} alt={val.title} />
                          </Link>
                        </div>
                        <div className="category category1">
                          <Link to={`/categorie/${val.category}`}>
                          <span>{val.category}</span></Link>
                        </div>
                      </div>
                      <div className="text">
                      <Link to={`/${val.slug}`}>
                        <h1 className="title">
                          {htmlParser(val.title) || "No title available..."}
                        </h1></Link>
                        <p className="desc">
                          {getExcerpt(val.description) || "No description available..."}
                        </p>
                        <div className="date">
                          <i className="fas fa-calendar-days"></i>
                          <DateComponent date={val.date} />
                        </div>
                        <div className="comment">
                          <Link to={`/autor/${val.writerName}`}>
                          <i className="fas fa-user"></i>
                          <label>{val.writerName || 0}</label></Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>Aucune nouvelle disponible.</p>
            )}
          </Slider>
        )}
      </div>

      {/* Pagination */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1} // Disable if on first page
          className="prev"
        >
          Previous
        </button>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages} // Disable if on last page
          className="next"
        >
          Next
        </button>
      </div>
    </section>
  );
};

export default Music;
