import React from 'react';

const DateComponent = ({ date }) => {
  // Create a new Date object from the date string
  const formattedDate = new Date(date).toLocaleDateString('fr-FR', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return <label>{formattedDate}</label>;
};

export default DateComponent;
