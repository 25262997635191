import React from 'react';
import './privacy-policy.css';

const PrivacyPolicy = () => {
  document.title = "Politique de confidentialité | AceBlog";
  // Check if the meta title already exists
  let metaTitleTag = document.querySelector('meta[name="title"]');
  if (!metaTitleTag) {
    // Create and append the meta title if it does not exist
    metaTitleTag = document.createElement("meta");
    metaTitleTag.name = "title";
    document.head.appendChild(metaTitleTag);
  }
  metaTitleTag.content = "Politique de confidentialité | AceBlog";
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">politique de confidentialité</h1>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Qui nous sommes</h2>
        <p className="privacy-policy-text">
          L'adresse de notre site Internet est : <a className="privacy-policy-link" href="https://aceblog.fr" target="_blank" rel="noopener noreferrer">https://aceblog.fr</a>.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Commentaires</h2>
        <p className="privacy-policy-text">
          Lorsque les visiteurs laissent des commentaires sur le site, nous collectons les données affichées dans le formulaire de commentaire, ainsi que l'adresse IP du visiteur et la chaîne de l'agent utilisateur du navigateur pour faciliter la détection du spam.
        </p>
        <p className="privacy-policy-text">
          Une chaîne anonymisée créée à partir de votre adresse e-mail (également appelée hachage) peut être fournie au service Gravatar pour voir si vous l'utilisez. La politique de confidentialité du service Gravatar peut être consultée ici : <a className="privacy-policy-link" href="https://aceblog.fr/privacy/" target="_blank" rel="noopener noreferrer">https://automattic.com/privacy/</a>. Une fois votre commentaire approuvé, votre photo de profil sera visible publiquement dans le contexte de votre commentaire.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Médias</h2>
        <p className="privacy-policy-text">
          Lorsque vous téléchargez des images sur le site Web, vous devez éviter de télécharger des images contenant des données de localisation intégrées (EXIF GPS). Les visiteurs du site Web peuvent télécharger et extraire des données de localisation arbitraires à partir des images du site Web.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Cookies</h2>
        <p className="privacy-policy-text">
          Si vous laissez un commentaire sur notre site, vous pouvez choisir d'enregistrer votre nom, votre adresse e-mail et votre site Web dans des cookies. Ces informations sont destinées à votre commodité afin que vous n'ayez pas à saisir à nouveau vos coordonnées lorsque vous laissez un autre commentaire. Ces cookies durent un an.
        </p>
        <p className="privacy-policy-text">
          Lorsque vous visitez notre page de connexion, nous définirons un cookie temporaire pour déterminer si votre navigateur accepte les cookies. Ce cookie ne contient aucune information personnelle et est supprimé lorsque vous fermez votre navigateur.
        </p>
        <p className="privacy-policy-text">
          Lors de votre connexion, nous mettrons également en place plusieurs cookies pour enregistrer vos informations de connexion et vos choix d'affichage à l'écran. Les cookies de connexion durent deux jours et les cookies d’options d’écran durent un an. Si vous sélectionnez « Rester connecté », votre connexion persistera pendant deux semaines. Lorsque vous vous déconnectez de votre compte, les cookies de connexion seront supprimés.
        </p>
        <p className="privacy-policy-text">
          Lorsque vous modifiez ou publiez un article, un cookie supplémentaire est stocké dans votre navigateur. Ce cookie ne contient aucune donnée personnelle et indique simplement l'identifiant de la publication de l'article que vous venez de modifier. Il expire après 1 jour.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Contenu intégré provenant d'autres sites Web</h2>
        <p className="privacy-policy-text">
          Les articles de ce site peuvent inclure du contenu intégré (par exemple des vidéos, des images, des articles, etc.). Le contenu intégré provenant d'autres sites se comporte de la même manière que si le visiteur avait visité l'autre site.
        </p>
        <p className="privacy-policy-text">
          Ces sites Web peuvent collecter des données sur vous, utiliser des cookies, intégrer un suivi tiers supplémentaire et surveiller votre interaction avec ce contenu intégré, y compris le suivi de votre interaction avec le contenu intégré si vous disposez d'un compte et êtes connecté à ce site Web.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Avec qui partageons-nous vos données</h2>
        <p className="privacy-policy-text">
          Lorsque vous demandez une réinitialisation de mot de passe, votre adresse IP sera incluse dans l'e-mail de réinitialisation.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Combien de temps nous conservons vos données</h2>
        <p className="privacy-policy-text">
          Lorsque vous laissez un commentaire, le commentaire et ses métadonnées sont conservés indéfiniment. Cela nous permet de reconnaître et d'approuver automatiquement les commentaires suivants au lieu de les conserver dans une file d'attente de modération.
        </p>
        <p className="privacy-policy-text">
          Pour les utilisateurs qui s'inscrivent sur notre site Web (le cas échéant), nous stockons également les informations personnelles qu'ils fournissent dans leur profil d'utilisateur. Tous les utilisateurs peuvent consulter, modifier ou supprimer leurs informations personnelles à tout moment (sauf qu'ils ne peuvent pas modifier leur nom d'utilisateur). Les administrateurs du site Web peuvent également consulter et modifier ces informations.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Vos droits</h2>
        <p className="privacy-policy-text">
          Si vous avez un compte sur ce site ou si vous avez laissé des commentaires, vous pouvez demander à recevoir un fichier exporté des données personnelles que nous détenons à votre sujet, y compris toutes les données que vous nous avez fournies. Vous pouvez également demander que nous supprimions toutes les données personnelles que nous détenons à votre sujet, à l'exception des données que nous sommes tenus de conserver à des fins administratives, juridiques ou de sécurité.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Où vos données sont envoyées</h2>
        <p className="privacy-policy-text">
          Les commentaires des visiteurs peuvent être vérifiés via un service automatisé de détection de spam.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Modifications de cette politique de confidentialité</h2>
        <p className="privacy-policy-text">
          Nous nous réservons le droit de mettre à jour ou de modifier cette politique de confidentialité à tout moment. Toute modification sera reflétée sur cette page avec une date de « Dernière mise à jour » mise à jour.
        </p>
      </section>

      <section className="privacy-policy-section">
        <h2 className="privacy-policy-heading">Contactez-nous</h2>
        <p className="privacy-policy-text">
          Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, veuillez nous contacter à <a className="privacy-policy-link" href="mailto:info@aceblog.fr">info@aceblog.fr</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
