import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import htmlParser from "html-react-parser"; // Import the html-react-parser library
import "./footer.css";

const Footer = () => {

  const [sportsNews, setSportsNews] = useState([]); // Store the news data
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Fetch sports news from the API
    fetch('https://aceblog.fr/admin/api/latest/news')
      .then((response) => response.json())
      .then((data) => {
        // Log the data to the console to inspect the response structure
        // console.log(data.news); // This will show the entire API response in the console
        
        // Check if the response is an array
        if (Array.isArray(data.news)) {
          setSportsNews(data.news); // Update state with fetched data
        } else {
          console.error('Erreur : la réponse de l API nest pas un tableau');
        }
        setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des dernières nouvelles:', error);
        setLoading(false); // Set loading to false on error as well
      });
  }, []); // Empty array means this will only run once when the component mounts
  

  if (loading) {
    return <div>Chargement...</div>; // Display a loading message until the data is fetched
  }

  return (
    <>
      <footer>
        <div className='container'>
          <div className='box'>
            <Link to="/"><img src='../images/logo.png' alt='' className='f-logo'/></Link>
            <p>AceBlog.Fr est une plateforme de nouvelles professionnelle en France. Nous vous proposons les actualités les plus récentes et intéressantes de toute la France.</p>
            <i className='fa fa-envelope'></i>
            <span><a href="mailto:info@aceblog.fr" className='email-link'>info@aceblog.fr</a></span><br/>
          </div>
          <div className='box'>
          <h3>Dernière</h3>
            {sportsNews.slice(0, 2).map((newsItem, index) => ( // Display first 2 news items
              <div key={index} className='item'>
                <a href={`/${newsItem.slug}`}><img src={newsItem.image} alt={newsItem.title} loading='lazy'/></a> {/* Assuming 'image' and 'title' are part of the API response */}
                <a href={`/${newsItem.slug}`}><p>{htmlParser(newsItem.title.slice(0, 80))}...</p></a> {/* Assuming 'title' is part of the API response */}
              </div>
            ))}
          </div>
          <div className='box'>
            <h3>Liens importants</h3>
            {/*<i className='fa fa-chevron-right'></i>*/}
            <ul>
              <li>
                <Link to="/à-propos -de-nous">À propos de nous</Link>
              </li>
              <li>
                <Link to="/contactez-nous">Contactez-nous</Link>
              </li>
              <li>
                <Link to="/politique-de-confidentialite">Politique de confidentialité</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className='legal  '>
        <div className='container flexSB'>
          <p>Droits d'auteur © 2024 | Tous droits réservés | Aceblog</p>
        </div>
      </div>
    </>
  )
}

export default Footer
