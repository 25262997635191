import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DateComponent from "../../date";

const Card = ({ item }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Check on initial load
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Link to={`/${item.slug}`} className="box" style={{ textDecoration: "none" }}>
     
      {/* The entire card is not wrapped in a link anymore */}
      <div className="img">
        {/* Wrapping only the image with a link to make the image clickable */}
        <Link to={`/${item.slug}`}>
          <img src={item.image} alt={item.title} loading="eager" />
        </Link>
      </div>
      <div className="text">
        <Link to={`/categorie/${item.category}`}>
          <span className="category">{item.category}</span>
        </Link>
        <h1 className="titleBg">{item.title}</h1>
        <div className="author flex">
          <a href={`/autor/${item.writerName}`}>
            <span>Par : {item.writerName}</span>
          </a>
          <DateComponent date={item.date} />
        </div>
      </div>
    </Link>
  );
};

export default Card;
